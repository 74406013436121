
import axios from 'axios';
export default {
    
    async createItems(params)  {
        return await axios.post(`items/create.php` , params)
    },
    async getAllItems()  {
        return await axios.get(`items/readAll.php`)
    },
    async getOneItems(item_id)  {
        return await axios.get(`items/readById.php?item_id=${item_id}`)
    },
    async getItemsByColumn(column , value)  {
        return await axios.get(`items/readByColumn.php?column=${column}&value=${value}`)
    },
    async deleteItems(item_id)  {
        return await axios.post(`items/delete.php?item_id=${item_id}`)
    },
    async updateItems(item_id , params)  {
        return await axios.post(`items/update.php?item_id=${item_id}` , params)
    }
}