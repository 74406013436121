<template>
    <div>
        <v-container >
            <v-layout row wrap>
            <v-flex xs4>
                <div v-if="fileName && fileName != 'no'">
                    <v-img style="border-radius: 4px;" height="40px"  :src="$imagePath + fileName" contain></v-img>
                </div>
                <div v-else-if="fileName == 'no'">
                    <!-- <v-icon size="40" color="black" class="ms-3">mdi-image-broken</v-icon> -->
                </div>
                <div v-else>
                    <v-icon size="40" color="black" class="ms-3">mdi-image-broken</v-icon> 
                </div>
            </v-flex>
            <v-flex xs8>
                <input type="file" style="display:none" @change="uploadFile" ref="file" name="file">
                <v-btn color="black" dark @click="openFile">
                    <v-icon>mdi-upload</v-icon>
                    {{ fileName ? 'Change' : 'Upload'  }}
                </v-btn>
            </v-flex>
        </v-layout>
        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            snackbar: {
                value: false,
                text: '',
                color: ''
            },
        }
    },
    props:{
        options : {
            type : Object,
            default : () => {
                return {
                    table_name : '',
                    column_name : '',
                    table_key_name : '',
                    table_key_value : '',
                }
            }
        },
        fileName : {
            type : String,
            default : ''
        }
    },
    methods: {
          openFile() {
            this.$refs.file.click();
          },
          uploadFile($event) {
                this.loading_btn = true
                var formData = new FormData();
                formData.append('table_name', this.options.table_name);
                formData.append('column_name', this.options.column_name);
                formData.append('table_key_name', this.options.table_key_name);
                formData.append('table_key_value', this.options.table_key_value);
                formData.append('file', $event.target.files[0]);
                axios.post('uploadFile.php',formData).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: 'Image Uplaoded',
                                color: 'success'
                            }
                            window.location.reload()
                        } else {
                            this.snackbar = {
                                value: true,
                                text: 'Fail to Upload Image',
                                color: 'error'
                            }
                        }
                    })
                    .finally(() => {
                        this.loading_btn = false
                    })
            },
    },
}
</script>